<template>
	<div
	class="tw-p-[24px] tw-flex tw-flex-col tw-h-full"
	>
		<HeaderSlot
		title="Livrables par campagne"
		subTitle="Accès aux livrables"
		/>

		<div class="tw-w-[300px] tw-mb-[10px]">
			<div class="search-field">
				<v-text-field
				v-model="campaignSearch"
				append-icon="mdi-magnify"
				label="Rechercher"
				single-line
				hide-details
				clearable
				dense
				/>
			</div>
		</div>

		<div class="tw-mb-[24px] tw-overflow-y-auto">
			<div
			class="tw-w-full bordered"
			>
				<v-data-table
				:loading="campaigns.length === 0"
				:headers="campaignsHeaders"
				:items="campaigns"
				:items-per-page="10"
				:search="campaignSearch"
				:hide-default-footer="campaigns.length < 6"
				>
					<template v-slot:item="{item}">
						<tr
						class="pointer"
						@click="handleClick(item)"
						>
							<td>{{ item.name }}</td>

							<td>{{ item.workspace.name }}</td>

							<td>{{ item.workspace.customerSite.name }}</td>

							<td>{{ item.deliveryCount }}</td>

							<td>{{ $t(item.status) }}</td>
							
							<td>
								<v-icon
								@click.stop.prevent="handleClickDashboard(item)"
								>
									mdi-open-in-app
								</v-icon>
								<v-icon
								@click.stop.prevent="downloadDeliverables(item)"
								color="#2a7221"
								v-if="$hasRight('files.downloadDeliverables') && (item.status === 'results_obtained' || item.status === 'status_archived' || item.status === 'finished')"
								>
									mdi-download
								</v-icon>
							</td>
						</tr>
					</template>

					<template v-slot:item.type="{ item }">
						<v-chip :color="getCampaignColor(item.type)">
							{{
								$t(item.type)
							}}
						</v-chip>
					</template>
				</v-data-table>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "CampaignsFilesList",
	data(){
		return {
			campaigns: [],
			campaignsHeaders: [
				{text: "Nom", value: "name", width: "15%"},
				{text: "Projet", value: "workspace.name", width: "15%"},
				{text: "Site client", value: "workspace.customerSite.name", width: "15%"},
				{text: "Nombre de livrables", value: "deliveryCount", width: "15%"},
				{text: "Statut", value: "status", width: "15%"},
				{
					text: "Actions", value: "edit", width: "10%"
				}
			],
			campaignSearch: ""
		};
	},
	computed: {
		
	},
	watch: {
		
	},
	methods: {
		async getCampaignsByUser(){
			let userId = this.$store.state.user.current.userId;
			if(userId === undefined){
				return setTimeout(this.getCampaignsByUser, 500);
			}
			this.campaigns = await this.$api.campaigns.findByUser(userId, true, true);
		},
		handleClick(item){
			this.$router.push({
				name: "campaign-deliverables",
				params: {
					id: item.id
				}
			});
		},
		handleClickDashboard(item){
			this.$router.push({
				name: "coring-campaign-dashboard",
				params: {
					id: item.id
				}
			});
		},
		getCampaignColor(campaignType){
			return campaignType == "coring" ? "#ffbd00" : "#ff2e00";
		},
		downloadDeliverables(item){
			let documentsArray = [];
			item.documents.forEach(document => {
				console.log(document);
				documentsArray.push(document.name);
			});
			this.$api.documents
			.zipSelected(documentsArray, item.id)
			.then(zipContent => {
				const url = window.URL.createObjectURL(new Blob([zipContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "livrables" + item.name + ".zip");
				link.click();
			});
		}
		
	},
	mounted(){
		this.$route.params.id = null;
		this.getCampaignsByUser();
	}
};
</script>

<style lang="scss" scoped>
.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}


    .table-action {
      cursor: pointer;
      margin: 5px;
	  align-self: center;
	  justify-self: center;
    }
</style>
