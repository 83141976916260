<template>
	<div
	id="project-files"
	class="tw-p-[24px] tw-h-full tw-flex tw-w-full tw-flex-col"
	>
		<HeaderSlot
		:title="$cap(campaign.name)"
		subTitle="Fichiers"
		@clickBackButton="$router.back()"
		>
			<ButtonSlot @click="$router.push({ name: 'project', params: { id: campaign.workspace.id } })">
				Accéder à la campagne
			</ButtonSlot>
		</HeaderSlot>

		<div class="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto">
			<div
			class="tw-flex tw-justify-center mb-5"
			v-if="nbFilesRequired > 0"
			>
				<v-expansion-panels
				v-model="expandRequiredDocuments"
				multiple
				flat
				style="width: 800px"
				class="requiredDocumentsBox"
				>
					<v-expansion-panel
					style="background-color: #f1f1f1"
					class="requiredDocumentsBox__panel"
					>
						<v-expansion-panel-header>
							<b>Documents à fournir ({{ nbFilesRequired }})</b>
						</v-expansion-panel-header>

						<v-expansion-panel-content>
							<v-list
							flat
							style="background-color: #f1f1f1"
							>
								<v-list-item-group color="primary">
									<div
									v-for="(requiredDocument, index) in requiredDocuments"
									:key="index"
									>
										<v-list-item
										:disabled="
											requiredDocument.status !== 'awaiting' &&
												requiredDocument.status !== 'refused'
										"
										>
											<v-list-item-icon>
												<v-icon>
													mdi-file-{{ requiredDocument.extension }}-box
												</v-icon>
											</v-list-item-icon>

											<v-list-item-content>
												<v-list-item-title class="tw-flex tw-items-center tw-justify-between tw-gap-[5px]">
													<div>
														{{ requiredDocument.name }} &nbsp;
														<v-chip
														small
														:color="getColor(requiredDocument.status)"
														>
															{{ $t(requiredDocument.status) }}
														</v-chip>
													</div>

													<div class="tw-flex tw-gap-[5px] tw-overflow-hidden">
														<ButtonSlot
														v-if="
															(requiredDocument.status === 'awaiting' ||
																requiredDocument.status === 'refused') &&
																$hasRight('files.sendAskedFile')
														"
														@click="addDocument(requiredDocument)"
														_small
														_icon="mdi-file-document-plus-outline"
														:_res="800"
														>
															Ajouter le document
														</ButtonSlot>

														<ButtonSlot
														v-if="
															(requiredDocument.status === 'awaiting' ||
																requiredDocument.status === 'refused') && 
																$hasRight('files.askAndValidateFiles')
														"
														_small
														_icon="mdi-trash-can-outline"
														_theme="gray"
														:_res="800"
														@click="deleteRequiredDocument(requiredDocument)"
														:_popup="{
															title: 'Confirmer l\'action.',
															subTitle: 'Êtes-vous sûr de vouloir annuler la demande ?',
														}"
														>
															Annuler la demande
														</ButtonSlot>
													</div>
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>

										<v-divider/>
									</div>
								</v-list-item-group>
							</v-list>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>

			<div class="tw-flex tw-gap-[5px] tw-mb-[10px]">
				<ButtonSlot
				v-if="$hasRight('files.askAndValidateFiles')"
				class="mr-2"
				@click="showAskDocument = true"
				_theme="light-gray"
				>
					Demander des documents
				</ButtonSlot>

				<ButtonSlot
				v-if="$hasRight('files.uploadCustomerFile')"
				class="mr-2"
				@click="addDocument(null)"
				_theme="light-gray"
				>
					Ajouter d'autres documents
				</ButtonSlot>
			</div>

			<div class="tw-flex">
				<v-data-table
				:headers="headers"
				:items="documents"
				:items-per-page="20"
				locale="fr-FR"
				class="elevation-0 bordered tw-w-full"
				:search="search"
				no-data-text="Aucun fichier n'a été importé"
				:hide-default-footer="documents.length <= 20"
				:footer-props="{
					'disable-items-per-page': true
				}"
				>
					<template v-slot:top>
						<v-text-field
						v-model="search"
						clearable
						label="Rechercher un fichier"
						class="mx-4"
						/>
					</template>

					<template v-slot:item.size="{ item }">
						{{ bytesToSize(item.size) }}
					</template>

					<template v-slot:item.createdAt="{ item }">
						{{ moment(item.createdAt).format("llll") }}
					</template>

					<template v-slot:item.download="{ item }">
						<v-row>
							<v-col md="8">
								<v-card
								v-if="!!item.requiredDocument"
								color="grey lighten-3"
								flat
								class="ma-1"
								>
									<v-card-text>
										<h4>
											Demande de document :
											{{
												item.requiredDocument ? item.requiredDocument.name : null
											}}
										</h4>

										<div
										class="mt-2"
										:style="
											item.requiredDocument !== null &&
												item.requiredDocument.status !== 'refused' &&
												item.requiredDocument.status !== 'validated' &&
												item.requiredDocument.status !== 'verification'
												? 'margin-bottom: -30px'
												: ''
										"
										>
											<v-select
											v-if="
												item.requiredDocument !== null &&
													item.requiredDocument.status !== 'refused' &&
													item.requiredDocument.status !== 'validated' &&
													$hasRight('files.askAndValidateFiles')
											"
											v-model="item.requiredDocument.status"
											:items="availableStatus"
											item-text="text"
											item-value="value"
											label="Statut"
											:loading="requiredDocumentLoading"
											dense
											outlined
											@change="
												updateRequiredDocumentStatus(
													$event,
													item.requiredDocument.id
												)
											"
											/>

											<v-chip v-else-if="item.requiredDocument !== null">
												{{
													$t(item.requiredDocument.status)
												}}
											</v-chip>
										</div>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col md="2">
								<div
								class="mt-9"
								v-if="item.requiredDocument !== null"
								/>

								<v-btn
								class="mx-2"
								depressed
								fab
								small
								@click="downloadDocument(item.s3Path, item.name)"
								>
									<v-icon dark>
										mdi-download
									</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</template>
				</v-data-table>
			</div>
		</div>

		<v-dialog
		v-if="$hasRight('files.uploadCustomerFile')"
		v-model="showAddDocument"
		width="800"
		>
			<v-card>
				<v-card-title> Ajouter de nouveaux documents </v-card-title>

				<v-form
				ref="sendDocumentForm"
				@submit.prevent="sendDocument()"
				>
					<v-card-text>
						<div class="d-flex justify-center mt-2 mb-2">
							<v-card
							width="500"
							v-if="
								requiredDocumentToUpload !== null &&
									$hasRight('files.sendAskedFile')
							"
							flat
							color="grey lighten-3"
							style="text-align: center"
							>
								<v-card-text>
									<h3 class="black--text">
										Document(s) à ajouter
									</h3>

									<br/>

									<v-icon>
										mdi-file-{{
											requiredDocumentToUpload.extension
										}}-box
									</v-icon>

									<b>{{ requiredDocumentToUpload.name }}</b>
								</v-card-text>
							</v-card>
						</div>

						<v-file-input
						v-model="documentFiles"
						label="Ajoutez un ou plusieurs fichiers"
						show-size
						truncate-length="15"
						:rules="rules"
						multiple
						/>
					</v-card-text>

					<v-divider/>

					<v-card-actions>
						<medium-button-slot
						@click="
							() => {
								showAddDocument = false;
								requiredDocumentToUpload = null;
							}
						"
						>
							Annuler
						</medium-button-slot>

						<v-spacer/>

						<div class="d-flex">
							<HighButtonSlot
							:_disabled="
								!correctExtension(requiredDocumentToUpload, documentFiles) ||
									isDocumentUploading ||
									documentFiles.length === 0
							"
							:_type="'submit'"
							>
								<v-icon
								class="rotating-icon"
								v-if="isDocumentUploading"
								>
									mdi-refresh
								</v-icon>
								Envoyer
								{{
									null !== requiredDocumentToUpload ? "pour vérification" : ""
								}}
							</HighButtonSlot>
						</div>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<v-dialog
		v-if="$hasRight('files.askAndValidateFiles')"
		v-model="showAskDocument"
		width="800"
		>
			<v-card class="popup__dmdDoc">
				<v-card-title> Demander un document </v-card-title>

				<v-form
				ref="askDocumentForm"
				@submit.prevent="askDocument()"
				>
					<v-card-text>
						<v-container
						v-for="(form, index) in askDocumentForms"
						:key="index"
						>
							<v-row>
								<v-col
								cols="12"
								md="8"
								>
									<v-text-field
									v-model="form.name"
									:counter="50"
									label="Nom du document"
									required
									clearable
									:rules="rules"
									/>
								</v-col>

								<v-col
								cols="12"
								md="3"
								>
									<v-select
									v-model="form.extension"
									:items="documentExtensions"
									:item-text="item => item.name"
									:item-value="item => item.id"
									:rules="rules"
									clearable
									label="Extension"
									/>
								</v-col>

								<v-col
								v-if="index > 0"
								class="mt-4"
								cols="12"
								md="1"
								>
									<v-btn
									@click="askDocumentForms.splice(index, 1)"
									text
									icon
									color="red lighten-2"
									>
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>

					<v-divider/>

					<v-card-actions>
						<low-button-slot @click="showAskDocument = false">
							Annuler
						</low-button-slot>

						<v-spacer/>

						<MediumButtonSlot
						@click="addAskDocumentForm()"
						class="mr-5"
						>
							Ajouter un autre document
						</MediumButtonSlot>

						<HighButtonSlot :_type="'submit'">
							Envoyer la demande
						</HighButtonSlot>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "CampaignFiles",
	data(){
		return {
			loading: true,
			campaign: {id: "", name: ""},
			askDocumentLoading: false,
			requiredDocumentLoading: false,
			search: "",
			requiredDocuments: [],
			documentFiles: [],
			showAddDocument: false,
			showAskDocument: false,
			requiredDocumentToUpload: null,
			expandRequiredDocuments: [],
			availableStatus: [
				{text: "Validé", value: "validated"},
				{text: "Refusé", value: "refused"},
				{text: "En attente", value: "awaiting"},
				{text: "Vérification", value: "verification"}
			],
			headers: [
				{text: "Nom", value: "name"},
				{text: "Ajouté par", value: "addedBy.email"},
				{text: "Date de création", value: "createdAt"},
				{text: "Taille du fichier", value: "size"},
				{text: "", value: "download"}
			],
			askDocumentForms: [{name: "", extension: ""}],
			documentExtensions: [
				{id: "excel", name: "xls"},
				{id: "pdf", name: "pdf"},
				{id: "png", name: "png"},
				{id: "jpg", name: "jpg"},
				{id: "word", name: "word"},
				{id: "other", name: "autre"}
			],
			documents: [],
			rules: [
				v => !!v || "Une valeur est requise",
				() => {
					if(null === this.requiredDocumentToUpload) return true;
					if(this.requiredDocumentToUpload.extension === "other") return true;
					return (
						this.correctExtension(
							this.requiredDocumentToUpload,
							this.documentFiles
						) || "L'extension du document ne correspond pas à celle demandée"
					);
				}
			],
			isDocumentUploading: false
		};
	},
	computed: {
		nbFilesRequired(){
			return this.requiredDocuments.filter(
				rd => rd.status !== "validated" && rd.status !== "verification"
			).length;
		}
	},
	methods: {
		async deleteRequiredDocument(requiredDocument){
			await this.$api.documents.deleteRequiredDocument(requiredDocument.id);
			await this.getRequiredDocuments();
      
		},
		async getCampaign(){
			this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
		},
		async getRequiredDocuments(){
			this.requiredDocuments =
        await this.$api.documents.findCampaignRequiredDocuments(
        	this.$route.params.id
        );
			if(this.nbFilesRequired > 0){
				this.expandRequiredDocuments = [0];
			}
		},
		async getDocuments(){
			this.documents = await this.$api.documents.findByCampaign(
				this.$route.params.id
			);
		},
		correctExtension(requiredDocument, selectedFiles){
			if(undefined === requiredDocument?.extension) return true;
			if(this.requiredDocumentToUpload.extension === "other") return true;
			let isCorrectExtension = true;
			if(selectedFiles) selectedFiles.forEach(file => {
				let nameParts = file.name.split(".");
				const extension = nameParts[1] || "unknown";
				if(requiredDocument.extension !== extension){
					isCorrectExtension = false;
				}
			});
			else isCorrectExtension = false;
			return isCorrectExtension;
		},
		addDocument(document){
			this.showAddDocument = true;
			this.requiredDocumentToUpload = document;
		},
		sendDocument(){
			if(this.$refs.sendDocumentForm.validate() === false){
				return;
			}
			this.isDocumentUploading = true;

			var re = /(?:\.([^.]+))?$/;

			this.documentFiles.forEach(documentFile => {
				var formData = new FormData();
				formData.append("file", documentFile);
				formData.append("campaignId", this.campaign.id);
				formData.append("category", "conversation");

				var extension = re.exec(documentFile.name)[1];

				this.$api.documents
				.upload(formData, {
					name: documentFile.name,
					extension: extension,
					size: documentFile.size,
					category: "conversation",
					campaignId: this.campaign.id,
					requiredDocumentId: this.requiredDocumentToUpload
						? this.requiredDocumentToUpload.id
						: null
				})
				.then(document => {
					if(this.requiredDocumentToUpload !== null){
						var requiredDocumentIndex = this.requiredDocuments.findIndex(
							obj => obj.id == this.requiredDocumentToUpload.id
						);
						this.requiredDocuments[requiredDocumentIndex].status =
                "verification";
					}
					this.documents.push(document);
					this.showAddDocument = false;
					this.isDocumentUploading = false;
				})
				.catch(() => (this.isDocumentUploading = false));
			});
		},
		askDocument(){
			if(this.$refs.askDocumentForm.validate() === false){
				return;
			}

			this.askDocumentLoading = true;

			this.askDocumentForms.forEach(value => {
				this.$api.documents
				.require({
					name: value.name,
					extension: value.extension,
					campaignId: this.$route.params.id
				})
				.then(document => {
					this.requiredDocuments.push(document);
				});
			});

			this.showAskDocument = false;
			this.askDocumentForms = [{name: "", extension: ""}];
			this.askDocumentLoading = false;

			// Reset validation in order to avoid red fields when reopening form, as values have changed
			this.$refs.askDocumentForm.resetValidation();
		},
		addAskDocumentForm(){
			this.askDocumentForms.push({name: "", extension: ""});
		},
		downloadDocument(documentPath, documentName){
			this.$api.documents.download(documentPath).then(response => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", documentName);
				link.click();
			});
		},
		getColor(status){
			if(status === "awaiting") return "orange";
			else if(status === "verification") return "primary";
			else if(status === "validated") return "green";
			else return "red";
		},
		bytesToSize(bytes){
			var sizes = [
				"Bytes", "KB", "MB", "GB", "TB"
			];
			if(bytes == 0) return "0 Byte";
			var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
		},
		updateRequiredDocumentStatus(status, requiredDocumentId){
			this.requiredDocumentLoading = true;
			this.$api.documents
			.review(requiredDocumentId, status)
			.then(requiredDocument => {
				var requiredDocumentIndex = this.requiredDocuments.findIndex(
					obj => obj.id == requiredDocumentId
				);
				this.requiredDocuments[requiredDocumentIndex].status =
            requiredDocument.status;
				this.requiredDocumentLoading = false;
			});
		}
	},
	mounted(){
		this.getCampaign();
		this.getRequiredDocuments();
		this.getDocuments();
	}
};
</script>

<style lang="scss">
#project-files {
  .requiredDocumentsBox {
    &__panel {
      padding: 15px;
    }

    .requiredDocumentTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .left {
        display: flex;
        align-items: center;
      }
    }
  }
}
.rotating-icon {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.v-data-footer {
  &__select {
    visibility: hidden !important;
  }
  &__pagination {
    visibility: hidden !important;
  }
}

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
